import "./Utils.css";
import Skeleton from "react-loading-skeleton";

const Address = ({ maintext }) => {
  return (
    <div className="address-item">
      <strong>{maintext}</strong>
    </div>
  );
};

const Container = ({ children }) => {
  return <div className="container">{children}</div>;
};

const Education = ({
  maintext,
  secondtext1,
  secondtext2,
  secondtext3,
  thirdtext,
  furthtext,
  fifthtext,
  sixtext,
  seventhtext,
  eighttext,
}) => {
  return (
    <div>
      <h2 className="maintext-title">{maintext}</h2>

      <div className="education__card">
        <div className="first-box">
          <ul className="linear">
            <li></li>
            <li></li>
            <li></li>
          </ul>

          <div className="first-box-item">
            <p>{secondtext1}</p>
            <p>{secondtext2}</p>
            <p>{secondtext3}</p>
          </div>
        </div>
        <div className="last-box">
          <div className="last-box-item">
            <h3>{thirdtext}</h3>
            <p>{furthtext}</p>
          </div>
          <div className="last-box-item">
            <h3>{fifthtext}</h3>
            <p>{sixtext}</p>
          </div>
          <div className="last-box-item">
            <h3>{seventhtext}</h3>
            <p>{eighttext}</p>
          </div>
        </div>
      </div>
    </div>
  );
};



const Crad = ({mainnumber, maintext}) => {
  return (
    <div className="card">
      <ul>
        <p>{mainnumber}</p>
      </ul>
      <h3>{maintext}</h3>
    </div>
  )
}

const Crad2 = ({mainnumber, maintext}) => {
  return (
    <div className="card2">
      <h3>{mainnumber}</h3>
      <p>{maintext}</p>
    </div>
  )
}

const Card3 = ({logo}) => {
  return (
    <div className="card3">
      <img src={logo} alt="" />
    </div>
  )
}

const Card4 = ({title, mainImg, link}) => {
  return (
    <a target="_blank" href={link}>
      <div className="card4">
        <div className="strong">
          <img src={title} />
        </div>
        <img src={mainImg} alt="" />
      </div>
    </a>
  )
}


const CardSkeleton = ({ amount, swiper }) => {
  return new Array(amount).fill("").map((skeleton, index) => (
    <div className="card4">
      <div className="strong">
        <Skeleton height={200} />
      </div>
      <Skeleton count={1}/>
    </div>
  ));
};


export { Container, Address, Education, Crad, Crad2, Card3, Card4, CardSkeleton };
