import { Address, Card4, Container } from "../../utils/Utils"
import "./Portfolio.css";
import html from '../../assets/images/s.jpg'
import a from '../../assets/images/a.webp'
import shop from '../../assets/images/SHOPPING.jpg'
import car from '../../assets/images/car.jpg'
import ecommerce from '../../assets/images/ecommerce.jpg'
import bag from '../../assets/images/bag.jpg'
import food from '../../assets/images/food.jpg'
import mold from '../../assets/images/mold.jpg';
import blogpost from '../../assets/images/blogpostt.jpg';
import moldlogo from '../../assets/images/moldlogo.svg';
import netflix from '../../assets/images/netflix.jpg'
import wether from '../../assets/images/wether.jpg'
import pixels from '../../assets/images/pixels.png'
import autotech from '../../assets/images/autotech.svg'
import spotify from "../../assets/images/spotify.jpg"
import logistiks from '../../assets/images/logistics.svg'
import netlogo from '../../assets/images/netlogo.png'
import wetherLogo from '../../assets/images/wetherlogo.png'
import pixelsLogo from '../../assets/images/pngwing.com.png'
import blogpostLogo from "../../assets/images/blogpostLogo.svg"
import spotifyLogo from "../../assets/images/spotifyLogo.png"
import cofee from "../../assets/images/cofee.jpg"
import cofeeLogo from "../../assets/images/cofeelogo.svg"


const Portfolio = () => {
  return (
    <div className='portfolio__wrapper'>
      <Container>
        <div className="portfolio">
          <Address maintext="PORTFOLIO" />
          <h3 className="portfolio__title">Creative Portfolio</h3>

            <div className="portfolio__cards">
              <Card4 link="https://xalqarologistika.uz/" title={logistiks} mainImg={html}/>
              <Card4 link="https://www.auto-tech.uz/" title={autotech} mainImg={a}/>
              <Card4 link="https://mold-clone.vercel.app/" title={moldlogo} mainImg={mold}/>
              <Card4 link="https://muvie-web.netlify.app/index.html" title={netlogo} mainImg={netflix}/>
              <Card4 link="https://blogpost-pied.vercel.app/" title={blogpostLogo} mainImg={blogpost}/>
              <Card4 link="https://scintillating-madeleine-3daa14.netlify.app/" title={wetherLogo} mainImg={wether}/>
              <Card4 link="https://shopping-six-iota.vercel.app/" title={""} mainImg={shop}/>
              <Card4 link="https://stalwart-frangipane-5e934f.netlify.app/" title={pixelsLogo} mainImg={pixels}/>
              <Card4 link="https://spotify-chi-two.vercel.app/index.html" title={spotifyLogo} mainImg={spotify}/>
              <Card4 link="https://benevolent-starship-0e9307.netlify.app/" title={cofeeLogo} mainImg={cofee}/>
              <Card4 link="https://cars-blush.vercel.app/" title={""} mainImg={car}/>
              <Card4 link="https://bags-ochre.vercel.app/" title={""} mainImg={bag}/>
              <Card4 link="https://ice-cream-jet.vercel.app/" title={""} mainImg={food}/>
              <Card4 link="https://something-azure-ten.vercel.app/" title={""} mainImg={ecommerce}/>
            </div>
        </div>
      </Container>
    </div>
  )
}

export default Portfolio