import { Address, Container, Education } from "../../utils/Utils";
import { AiFillCaretRight } from 'react-icons/ai'
import "./About.css";
import resume from "../../assets/file/abduazim-resume.pdf";
import { saveAs } from "file-saver";

import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


const About = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div className="about__wrapper">
      <Container>
        <div className="about__content">
          <Address maintext="ABOUT" />
          <h3 className="about__title">About Me</h3>
          <div
            // onClick={() => saveAs("https://drive.google.com/file/d/1d5QcATgyuVTTiDMc52ruXHcFMuH3iVeB/view", "image")}
            className="img">

            <Viewer
              fileUrl={resume}
              plugins={[
                defaultLayoutPluginInstance
              ]}
            />
          </div>
          <h2>Khudoyberdiyev Abduazim</h2>
          <p>Fronend Deweloper</p>
          <div className="line"></div>
          <p>
            An accomplished video editor with more than several years of
            experience in various industries such as entertainment, media
            companies and public relation. Strong background in filming and
            editing videos with the help of Adobe Premiere Pro, and After
            Effect. Looking to work in a challenging and dynamic environment.
          </p>
          <div className="line"></div>
          <ul className="about__menu">
            <ul className="about__first-item">
              <ul className="about__menu-item">
                <strong>Age:</strong>
                <p>18</p>
              </ul>
              <ul className="about__menu-item">
                <strong>Email:</strong>
                <p width={"100px !important"}>
                  <a className="about__menu-item-a" href="mailto: abduazimkhudoyberdiyev@gmail.com">abduazimkhudoyberdiyev@gmail.com</a>
                </p>
              </ul>
            </ul>

            <ul>
              <ul className="about__menu-item">
                <strong>Nationality:</strong>
                <p>Uzbekistan</p>
              </ul>
              <ul className="about__menu-item">
                <strong>Study:</strong>
                <p>Najot Ta'lim</p>
              </ul>
              <ul className="about__menu-item">
                <strong>Degree:</strong>
                <p>heigh-school</p>
              </ul>
              <ul className="about__menu-item">
                <strong>Freelance:</strong>
                <p>Available(party)</p>
              </ul>
            </ul>
          </ul>
          <div className="line"></div>
        </div>

        <div className="skills__wrapper">
          <div>
            <h2 className="skills__title">Knowledge</h2>
            <div className="skills__menu">
              <ul className="skills__menu-item">
                <strong className="st"><AiFillCaretRight /> &nbsp;</strong><p> Html5</p>
              </ul>
              <ul className="skills__menu-item">
                <strong className="st"><AiFillCaretRight /> &nbsp;</strong><p> Css3</p>
              </ul>
              <ul className="skills__menu-item">
                <strong className="st"><AiFillCaretRight /> &nbsp;</strong><p> Scss</p>
              </ul>
              <ul className="skills__menu-item">
                <strong className="st"><AiFillCaretRight /> &nbsp;</strong><p> Sass</p>
              </ul>
              <ul className="skills__menu-item">
                <strong className="st"><AiFillCaretRight /> &nbsp;</strong><p> Bootstrap</p>
              </ul>
              <ul className="skills__menu-item">
                <strong className="st"><AiFillCaretRight /> &nbsp;</strong><p> Tailwind Css</p>
              </ul>
              <ul className="skills__menu-item">
                <strong className="st"><AiFillCaretRight /> &nbsp;</strong><p> JavaScript</p>
              </ul>
              <ul className="skills__menu-item">
                <strong className="st"><AiFillCaretRight /> &nbsp;</strong><p> React Js</p>
              </ul>
            </div>
          </div>
          <div>
            <h2 className="skills__title">Interests</h2>
            <div className="skills__menu">
              <ul className="skills__menu-item">
                <strong className="st"><AiFillCaretRight /> &nbsp;</strong><p> Kyber Security</p>
              </ul>
            </div>
          </div>
        </div>
      </Container>
      <div className="education__wrapper">
        <Container>
          <div className="education__box">
            <Education maintext="Education" secondtext1="2016 - 2019" secondtext2="2019-2023 (exp.)" secondtext3="2007-2013" thirdtext="College of information technologies" furthtext="High-school" fifthtext="" sixtext="Bachelor Degree" seventhtext="Elementery" eighttext="" />
            <Education maintext="Education" secondtext1="2016 - 2019" secondtext2="2019-2023 (exp.)" secondtext3="2007-2013" thirdtext="College of information technologies" furthtext="High-school" fifthtext="" sixtext="Bachelor Degree" seventhtext="Elementery" eighttext="" />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default About;
