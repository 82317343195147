import { NavLink } from "react-router-dom";
import "./Nav.css";
import { useEffect, useRef } from "react";
import { CiMenuBurger } from "react-icons/ci";
import { AiOutlineClose } from "react-icons/ai";

const Nav = () => {
  const btn1 = useRef();
  const btn2 = useRef();
  const modal = useRef();
  const li = useRef();
  const handleModal = (e) => {
    if (e.target.closest("button").dataset.btnFirst) {
      modal.current.style = `
                visibility: visible;
                transform: translateX(0);
            `;
      btn1.current.style = `display:none`;
      btn2.current.style = `display:block`;
    }
    if (e.target.closest("button").dataset.btnLast) {
      modal.current.style = `
                visibility: hidden;
                transform: translateX(100%);
            `;
      btn1.current.style = `display:block`;
      btn2.current.style = `display:none`;
    }
  };

  const handleModalToggle = (e) => {
    if (e.target.closest("li")) {
      modal.current.style = `
            visibility: hidden;
            transform: translateX(100%);
        `;
      btn1.current.style = `display:block`;
      btn2.current.style = `display:none`;
    }
  };

  return (
    <>
      <div className="nav__wrapper">
        <h1 className="nav__title">Abduazim</h1>
        <ul className="nam__menu">
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "link link--active" : "link"
              }
              to="/"
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "link link--active" : "link"
              }
              to="/about"
            >
              About
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "link link--active" : "link"
              }
              to="/service"
            >
              Service
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "link link--active" : "link"
              }
              to="/portfolio"
            >
              Portfolio
            </NavLink>
          </li>
          <li className="creator">
            {" "}
            &copy; 2023 Uzbekistan <br /> Created By &nbsp;
            <a href="https://github.com/abduazimkh">abduazimkh</a>
          </li>
        </ul>
      </div>

      <div className="nav">
        <h1>ABU</h1>
        <ul onClick={handleModal}>
          <button ref={btn1} data-btn-first>
            <CiMenuBurger />
          </button>
          <button ref={btn2} data-btn-last>
            <AiOutlineClose />
          </button>
        </ul>
      </div>

      <div onClick={handleModalToggle} ref={modal} className="modal">
        <ul className="modal__menu">
          <li className="hoverr">
            <NavLink
              className={({ isActive }) =>
                isActive ? "hoverr link link--active" : "link hoverr"
              }
              to="/"
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "hoverr link link--active" : "link hoverr"
              }
              to="/about"
            >
              About
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "hoverr link link--active" : "link hoverr"
              }
              to="/service"
            >
              Service
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "hoverr link link--active" : "link hoverr"
              }
              to="/portfolio"
            >
              Portfolio
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Nav;
