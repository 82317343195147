import "./Service.css"
import { Address, Card3, Container, Crad, Crad2 } from '../../utils/Utils'
import firstLogo from '../../assets/images/yoshlar.png' 
import secondLogo from '../../assets/images/ittifoq.png' 
import lastLogo from '../../assets/images/paynet.png' 
import binary from '../../assets/images/the_binary_inc_logo.jpeg' 

const Service = () => {
  return (
    <div className='service__wrapper'>
      <Container>
        <div className="whatido">
          <Address maintext="SERVICES" />
        </div>
        <h3 className="service__title">What I Do</h3>

        <div className="cards">
          <Crad mainnumber="01" maintext="eCommerce Website" />
          <Crad mainnumber="02" maintext="Multi Page Websites" />
          <Crad mainnumber="03" maintext="" />
          <Crad mainnumber="04" maintext="" />
          <Crad mainnumber="05" maintext="" />
          <Crad mainnumber="06" maintext="" />
        </div>
      </Container>
      <div className="company__projects">
        <Container>
          <h3 className="projects__title">Projects/People/Companies I work(ed) for</h3>
          <div className="projects__wrapper">
            <Card3 logo={binary} />
            <Card3 logo={secondLogo} />
            <Card3 logo={lastLogo} />
            <Card3 logo={secondLogo} />
            <Card3 logo={secondLogo} />
            <Card3 logo={firstLogo} />
            <Card3 logo={secondLogo} />
            <Card3 logo={lastLogo} />
            <Card3 logo={firstLogo} />
            <Card3 logo={secondLogo} />
            <Card3 logo={lastLogo} />
            <Card3 logo={firstLogo} />
            <Card3 logo={secondLogo} />
            <Card3 logo={lastLogo} />
          </div>
        </Container>
      </div>
      <div className="fun-facts">
        <Container>
          <h2 className="fun-title">Fun Facts</h2>

          <div className="cards-facts">
            <Crad2 mainnumber="10+" maintext="Projects Completed" />
            <Crad2 mainnumber="75+" maintext="Contents I made" />
            <Crad2 mainnumber="000" maintext="Not Yet" />
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Service