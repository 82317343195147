import "./App.css";
import Nav from "./components/nav/Nav";
import Home from "./routes/home/Home";
import About from "./routes/about/About";
import Service from "./routes/service/Service";
import Portfolio from "./routes/portfolio/Portfolio";
import BackToTop from "./components/backtotop/Backtotop";
import { Routes, Route } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import  { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function App() {
  const cursor = useRef();
  const cursor2 = useRef();

  const [coordinates, setCoordinates] = useState({
    x: 0,
    y: 0,
  });

  window.addEventListener("mousemove", (e) => {
    setCoordinates({
      x: e.clientX,
      y: e.clientY,
    });
  });

  

  useEffect(() => {
    cursor.current.style = `top: ${coordinates.y}px; left: ${coordinates.x}px`;
    cursor2.current.style = `top: ${coordinates.y}px; left: ${coordinates.x}px`;
  }, [coordinates]);

  return (
    <div className="App">
      <BackToTop />
      <Nav />
    <SkeletonTheme baseColor="#b2b2b2" highlightColor="lightgray" duration={0.5}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="service" element={<Service />} />
        <Route path="portfolio" element={<Portfolio />} />
      </Routes>
    </SkeletonTheme>

      <div ref={cursor} className="cursor"></div>
      <div ref={cursor2} className="cursor2"></div>
    </div>
  );
}

export default App;
