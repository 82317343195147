import "./Home.css";
import { TiSocialInstagram } from 'react-icons/ti'
import { IoLogoLinkedin } from 'react-icons/io5'
import { FaTelegram } from 'react-icons/fa'
import { BsGithub } from 'react-icons/bs'
import { Container } from "../../utils/Utils";

const Home = () => {

  return (
    <div className="home__wrapper">
      <Container>
      <div className="content">
        <div className="box"></div>
        <div>
          <h1>KHUDOYBERDIYEV</h1>
          <p>
          I am very passionate about building interactive responsive websites using the latest web technologies. This provides added value to my clients and their customers on any internet device they use.
          </p>
          <div className="icons">
            <a className="hoverr" target="_blank" href="https://www.linkedin.com/in/abduazim-khudoyberdiyev-61b535253/"><IoLogoLinkedin/></a>
            <a className="hoverr" target="_blank" href="https://www.instagram.com/abduazim_kh/"><TiSocialInstagram/></a>
            <a className="hoverr" target="_blank" href="https://t.me/abduazim_kh"><FaTelegram/></a>
            <a className="hoverr" target="_blank" href="https://github.com/abduazimkh"><BsGithub/></a>
          </div>
        </div>
      </div>
      </Container>
    </div>
  );
};

export default Home;
